@import '@/views/variables.scss';

:root {
  --spacer: 12px;
  --primaryColor: #F15152;
  --primaryText: #FFFFFF;
  --secondaryColor: #063852;
  --secondaryText: #FFFFFF;
  --headerText: #FFFFFF;
  --redColor: #DD0000;
  --lightGrayColor: #EEEEEE;
  --borderColor: #DDDDDD;
  --fieldBg: #FFFFFF;
  --fieldBorderWidth: 1px;
  --fieldBorderColor: #DDDDDD;
  --fieldText: #000000;
  --fieldOutlineColor: #F15152;
  --fieldBorderRadius: 6px;
  --fieldLabelColor: #063852;
  --buttonBorderRadius: 6px;
  --tileBorderRadius: 12px;
  --tileBoxShadow: 0 0 10px rgba(0,0,0,0.1);
  --tileBorder: 0px;
  --tileSpacing: 24px;
  --colorYellow: #E6DF44;
  --colorRed: #F15152;
  --colorGreen: #6ec18f;
  --colorBlue: #063852;
  --dropdownArrow: url('data:image/svg+xml,%3Csvg%20fill%3D%22%23999999%22%20height%3D%22512%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%22512%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20clip-rule%3D%22evenodd%22%20d%3D%22m4.93934%2010.9393c.58579-.5857%201.53553-.5857%202.12132%200l8.93934%208.9394%208.9393-8.9394c.5858-.5857%201.5356-.5857%202.1214%200%20.5857.5858.5857%201.5356%200%202.1214l-10%2010c-.5858.5857-1.5356.5857-2.1214%200l-9.99996-10c-.58579-.5858-.58579-1.5356%200-2.1214z%22%20fill%3D%22%23999999%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
}

body, div, span, a, button, input, textarea, section, label, h1, h2, h3, h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

div, span, a, i {
  box-sizing: border-box;
  text-decoration: none;
  color: #000000;
}

button, input, textarea, select {
  appearance: none;
  border: 0;
  background-color: transparent;
  background: none;
  outline: none;
  box-shadow: none;
  box-sizing: border-box;
}

.page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
main {
  flex: 1 1 auto;
  display: flex;
  width: 100vw;
  position: relative;
}

.page-centered {
  align-items: center;
  justify-content: center;
}

.flex-centered {
  align-items: center;
  justify-content: center;
}

section {
  display: block;
  padding-bottom: spacer(xl);
  padding-left: spacer(xl);
  padding-right: spacer(xl);
  > section {
    padding-left: 0;
    padding-right: 0;
  }
  &.shaded {
    padding: spacer(xl);
    margin-bottom: spacer(xl);
    background-color: #F5F5F5;
    border-radius: var(--tileBorderRadius);
    &:last-child {
      margin-bottom: 0;
    }
  }
}

h2 {
  flex: 0 0 auto;
  display: block;
  padding: 0 0 spacer(xl) 0;
  font-size: 2rem;
  font-weight: 700;
}

.text-bold { font-weight: 700 }
.text-align-center { text-align: center; }
.text-align-right { text-align: right; }

.paging-icon {
  flex: 0 0 auto;
  height: 20px !important;
  width: 20px !important;
  svg {
    height: 16px !important;
    width: 16px !important;
  }
}

.pill {
  padding-left: 8px;
  padding-right: 8px;
}